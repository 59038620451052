<template>
  <div class="tasks-update page-layout">
    <PageHeader
      title="Informations de la tâche"
      :has-back="true"
      :has-actions="true"
      @back="onGlobalCancel"
    >
      <md-button
        :disabled="!isInfosModified"
        class="md-raised"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="onGlobalSave"
      >
        Sauvegarder
      </md-button>
    </PageHeader>

    <LoadingCard v-if="isLoading" />

    <!-- ## Les informations de l'entreprise ## -->
    <md-card v-if="(isCollab || isAdmin) && !isLoading" class="meep-form">
      <!-- le formulaire de création -->
      <md-card-content>
        <!-- Titre -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('title') }">
            <label>Titre *</label>

            <md-input
              v-model="task.title"
              v-validate="'required'"
              name="title"
              type="text"
            />
            <span v-show="errors.has('title')" class="md-error">
              {{ errors.first("title") }}
            </span>
          </md-field>
        </div>

        <!-- Description-->
        <div class="meep-textarea">
          <ckeditor
            v-model="task.description"
            :editor="editor"
            :config="editorConfig"
            tag-name="textarea"
          />
        </div>

        <!-- Date Limite-->
        <div class="meep-input">
          <md-datepicker
            v-model="task.deadline"
            :md-disabled-dates="isDateInvalid"
          >
            <label>Date Limite *</label>
          </md-datepicker>
        </div>

        <div class="meep-input">
          <md-field>
            <label>Assigné à</label>
            <md-select v-model="task.user_id" name="collab">
              <md-option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.firstname }} {{ user.lastname }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="meep-input">
          <md-field>
            <label>Entreprise liée *</label>

            <md-select v-model="task.company_id" name="collab">
              <md-option
                v-for="company in companies"
                :key="company.id"
                :value="company.id"
              >
                {{ company.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <md-checkbox v-model="task.status">
          {{ $t("task.update-complete") }}
        </md-checkbox>

        <!-- Le bouton annuler -->
        <div class="tasks-update__actions">
          <md-button
            class="md-raised"
            :class="{
              'md-accent': isInfosModified,
              'meep-disabled': !isInfosModified,
            }"
            :disabled="!isInfosModified"
            @click.native="onInfosCancel"
          >
            Annuler
            <md-icon>clear</md-icon>
          </md-button>

          <!-- Le bouton sauvegarder -->
          <md-button
            class="md-raised"
            :class="{
              'md-primary': isInfosModified,
              'meep-disabled': !isInfosModified,
            }"
            :disabled="!isInfosModified"
            @click.native="onInfosSave"
          >
            Sauvegarder
            <md-icon>save</md-icon>
          </md-button>

          <md-button
            v-if="isAdmin"
            class="md-raised md-primary"
            @click.native="openConfirmDeleteModal"
          >
            Supprimer cette tâche
            <md-icon>delete_forever</md-icon>
          </md-button>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :text="confirmGlobalCancelModalText"
      :object-to-act-upon="{}"
      @close="closeConfirmGlobalCancelModal"
      @confirm="goBack"
    />

    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :object-to-act-upon="task"
      @close="closeConfirmDeleteModal"
      @confirm="onDelete"
    />
  </div>
</template>

<script>
/* Modèles */
import tasksModel from "../../model/tasks";
import usersModel from "../../model/users";
import companiesModel from "../../model/companies";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LoadingCard from "@/components/LoadingCard";

/* Composants */
import confirmActionModal from "../../components/modal/confirm-action";
import { USERS } from "@/constants";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import { sortByKey } from "@/services/util";
import CKEditorConfig from "@/mixins/CKEditorConfig";

export default {
  name: "UpdateTask",

  components: {
    LoadingCard,
    PageHeader,
    ckeditor: CKEditor.component,
    "confirm-action-modal": confirmActionModal,
  },

  mixins: [CKEditorConfig],

  data() {
    return {
      isLoading: false,
      error: false,

      /* On se sert des mêmes fonctions pour cancel et l'initialisation dans beforeMounted, alors on utilise ce flag pour éviter que des toast n'apparaissent tout de suite lorsque l'utilisateur entre sur la page */
      canShowToastedMessages: false,

      /* Informations de la taacge */
      task: {},
      users: [],
      company: "",
      companies: [],

      editor: ClassicEditor,

      /* Tampons pour éditer les informations */
      newInfos: [],

      /* Si les modals sont ouverts */
      isConfirmGlobalCancelModalOpen: false,
      isConfirmDeleteModalOpen: false,

      /* Texte des modals */
      confirmGlobalCancelModalText: {
        header: "Des modifications n'ont pas étés sauvegardées",
        body() {
          return "";
        },
        question: "Êtes-vous sûr de vouloir quitter cette page ?",
      },
      confirmDeleteModalText: {
        header: "Suppression d'une tâche",
        body(task) {
          return (
            "Vous êtes sur le point de supprimer la tâche <b>" +
            task.title +
            "</b>"
          );
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    /* ## EVENEMENTS ## */
    goBack() {
      window.history.back();
    },

    isDateInvalid(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    onGlobalSave() {
      if (this.isInfosModified) {
        this.onInfosSave();
      }
    },
    onGlobalCancel() {
      if (this.isInfosModified) {
        this.openConfirmGlobalCancelModal();
      } else {
        this.goBack();
      }
    },

    onInfosSave() {
      this.$validator.validateAll().then(result => {
        if (this.task.user_id === "") {
          this.$toasted.global.AppError({
            message: this.$t("update-task.error-collaborator"),
          });

          return;
        }
        if (this.task.company_id === "") {
          this.$toasted.global.AppError({
            message: "Vous devez choisir une entreprise à associer",
          });

          return;
        }
        if (result) {
          this.task.deadline.setHours(new Date().getHours());
          tasksModel
            .update(this.task)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: this.$t("task.update-success"),
              });

              this.$router.push("/dashboard/tasks/");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    onInfosCancel() {
      this.newInfos = {
        id: this.task.id,
        title: this.task.title,
        description: this.task.description,
        deadline: this.task.deadline,
        user_id: this.task.user_id,
        status: this.task.status,
        company_id: this.task.company_id,
      };

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onDelete() {
      tasksModel
        .remove(this.task.id)
        .then(() => {
          this.$router.push("/dashboard/tasks/");
          this.$toasted.global.AppSucces({
            message: "La tâche '" + this.task.title + "' à bien été supprimée",
          });
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    openConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = true;
    },
    closeConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = false;
    },

    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },

    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },

    async getData() {
      try {
        this.isLoading = true;
        this.error = false;

        const task = await tasksModel.get(this.$route.params.id);
        task.deadline = new Date(task.deadline);

        this.task = task;

        this.onInfosCancel();
        this.canShowToastedMessages = true;
        const users = await usersModel.getUsersInOrganization();

        this.users = users.filter(
          user => user.scope === USERS.ADMIN || user.scope === USERS.COLLAB
        );

        this.company = await companiesModel.get(this.task.company_id);

        const companies = await companiesModel.getAll();

        this.companies = this.companies = sortByKey(companies, "name");
      } catch (err) {
        this.error = true;

        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "self", "isJEP"]),

    isInfosModified() {
      if (this.task.title !== this.newInfos.title) return true;
      if (this.task.description !== this.newInfos.description) return true;
      if (this.task.deadline !== this.newInfos.deadline) return true;
      if (this.task.user_id !== this.newInfos.user_id) return true;
      if (this.task.status !== this.newInfos.status) return true;

      return this.task.company_id !== this.newInfos.company_id;
    },
  },

  watch: {
    $route: "getData",
  },
};
</script>

<style lang="scss" scoped>
.tasks-update {
}
</style>
